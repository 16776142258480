import React from 'react';
import pabbly from '../../images/integrations/pabbly.png';
import IntegrationBot from '../../components/integrations/indegrations';

const PabblyPage = () => (
  <IntegrationBot
    botLogo={pabbly}
    botName={'Pabbly Connect'}
  />
);

export default PabblyPage;
